<template>
  <router-view/>
</template>

<style lang="scss">
body {
	font-family: sans-serif;
}

td,
th {
	padding-right: 20px;
	text-align: left;
}
</style>
